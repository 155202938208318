import "./style.css";
// import searchLogo from '../../../assets/search.png'
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import kisiLogo from "../../assets/logo.svg";
import fbLogo from "../../assets/fb.ico";
import instagramLogo from "../../assets/instagram.ico";
import twitterLogo from "../../assets/twitter.png";
import linkedinLogo from "../../assets/linkedin.ico";
import tiktokLogo from "../../assets/tiktok.ico";
import ojk from "../../assets/ojk.png";
import idx from "../../assets/idx.png";
import idclear from "../../assets/idclear.png";
import ksei from "../../assets/ksei.png";
import sipf from "../../assets/sipf.png";
import {
  useNavigate,
  useParams,
  Navigate,
  useLocation,
} from "react-router-dom";
// import playStore from "../../assets/playStore.png";
// import appStore from "../../assets/appStore.png";
import playStore from "../../assets/icon-download-android.svg";
import appStore from "../../assets/icon-download-ios.svg";

export default function Footer(props) {
  const page = window.location.pathname;
  const params = useLocation();
  const currentPath = params.pathname;
  const isNoFooter = () => {
    if (
      page !== "/koins/reset-password" &&
      currentPath !== "/confirmation" &&
      currentPath.substring(0, 17) !== "/signature-spouse"
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    // console.log(page);
  }, [page]);
  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  const navigate = useNavigate();
  const changePage = (route) => {
    // console.log("masuk");

    navigate(route);
  };

  const conditionalNavigation = () => {
    // console.log("asw");
    navigate("/disclaimer", { page: "kuda" });
  };

  const groupList = [
    {
      name: "Korea Investment Holding",
      site: "https://www.koreaholdings.com/en/index",
    },
    {
      name: "Korea Investment & Securities",
      site: "http://www.truefriend.com/eng/main.jsp",
    },
    {
      name: "Korea Investment Management",
      site: "http://www.kim.co.kr/en",
    },
    {
      name: "Korea Investment Value Asset Management",
      site: "http://www.koreavalueasset.com/eng/main.do",
    },
    {
      name: "Korea Investment Partners",
      site: "http://partners.koreainvestment.com/?lang=en&ckattempt=1",
    },
    {
      name: "Korea Investment Saving Bank",
      site: "https://sb.koreainvestment.com/",
    },
    {
      name: "Korea Investment Capital",
      site: "http://kicf.co.kr/",
    },
    {
      name: "Kakao Bank of Korea",
      site: "https://www.kakaobank.com/",
    },
  ];
  return (
    <>
      {/* {params.pathname} */}
      {isNoFooter() ? (
        <div class="footer">
          <div class="upperFooter">
            <div class="logoWrapper addressFooter">
              <div class="monsterratBold grey headquarterTextFooter">
                Headquarters
              </div>
              <div class="monsterratMedium grey addressDetailFooter" style={{}}>
                <div class="addressDetailText monsterratBold">
                  {" "}
                  PT Korea Investment And Sekuritas Indonesia
                </div>
                <div
                  class="addressDetailText"
                  onClick={() => {
                    openInNewTab(
                      "https://www.google.com/maps/place/PT+Korea+Investment+%26+Sekuritas+Indonesia/@-6.2262535,106.8086006,15z/data=!4m5!3m4!1s0x0:0xe8aa59ec2f51cb73!8m2!3d-6.2262535!4d106.8086006"
                    );
                  }}
                >
                  Equity Tower, 9th Floor Suite A SCBD Lot 9.<br></br> Jl. Jend.
                  Sudirman Kav. 52-53, Jakarta 12190 Indonesia
                </div>
                <div class="addressDetailText"> Telepon: 021 2991 1911</div>
                <div class="addressDetailText">Email: cc@kisi.co.id</div>
              </div>
            </div>
            {page.substring(0, 12) == "/tentangkami" ||
            page == "/" ||
            (page.substring(0, 7) == "/produk" &&
              page.substring(8, page.length) !== "reksadana" &&
              page.substring(8, page.length) !== "saham") ||
            page == "/kisimobile" ||
            page == "/koins" ? (
              <div class=" socmedFooterContainer socmedFooterContainer1">
                <div class="socmedFooterWrapper">
                  {" "}
                  <div class="temukanTextFooter monsterratBold grey f15r">
                    Mulai Sekarang!
                    <div class="followSocmedImgContainer socmedFooter">
                      <div>
                        <img
                          onClick={() =>
                            openInNewTab(
                              "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                            )
                          }
                          class="downloadImgFooter mb-2"
                          src={playStore}
                        />
                      </div>
                      <div>
                        <img
                          onClick={() =>
                            openInNewTab(
                              "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                            )
                          }
                          class="downloadImgFooter mb-2"
                          src={appStore}
                        />
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            ) : (
              <div class=" socmedFooterContainer socmedFooterContainer1">
                <div class="socmedFooterWrapper">
                  {" "}
                  <div class="temukanTextFooter monsterratBold grey f15r">
                    Temukan Kami
                    <div class="followSocmedImgContainer socmedFooter">
                      <img
                        class="socmedFooterImg"
                        src={instagramLogo}
                        onClick={() => {
                          openInNewTab(
                            "https://www.instagram.com/kisi_sekuritas/"
                          );
                        }}
                      />
                      <img
                        class="socmedFooterImg"
                        src={tiktokLogo}
                        onClick={() => {
                          openInNewTab(
                            "https://www.tiktok.com/@kisi_sekuritas"
                          );
                        }}
                      />
                      <img
                        class="socmedFooterImg "
                        src={fbLogo}
                        onClick={() => {
                          openInNewTab(
                            "https://www.facebook.com/koreainvestment.id/"
                          );
                        }}
                      />
                      <img
                        class="socmedFooterImg "
                        src={linkedinLogo}
                        onClick={() => {
                          openInNewTab(
                            "https://www.linkedin.com/company/korea-investment-and-sekuritas-indonesia-pt/mycompany/"
                          );
                        }}
                      />

                      <img
                        class="socmedFooterImg twitFil"
                        src={twitterLogo}
                        onClick={() => {
                          openInNewTab("https://x.com/kisi_sekuritas");
                        }}
                      />
                    </div>
                  </div>{" "}
                </div>
              </div>
            )}
            <div class="copyright monsterratLight">
              <div class="monsterratBold grey headquarterTextFooter">
                &nbsp;
              </div>
              <div class="monsterratMedium grey koreaGroup">
                <b> Family Site Of KOREA INVESTMENT AND SEKURITAS INDONESIA</b>
              </div>
              <div class="monsterratMedium groupMember">
                <div>
                  <div
                    onClick={() =>
                      openInNewTab("https://www.koreaholdings.com/en/index")
                    }
                    class="koreaGroupLink"
                  >
                    Korea Investment Holdings
                  </div>
                  <div
                    onClick={() =>
                      openInNewTab("http://www.truefriend.com/eng/main.jsp")
                    }
                    class="koreaGroupLink"
                  >
                    Korea Investment & Securities
                  </div>
                  <div
                    onClick={() => openInNewTab("http://www.kim.co.kr/en")}
                    class="koreaGroupLink"
                  >
                    Korea Investment Management
                  </div>
                  <div
                    onClick={() =>
                      openInNewTab("http://www.koreavalueasset.com/eng/main.do")
                    }
                    class="koreaGroupLink"
                  >
                    Korea Investment Value Asset Management
                  </div>
                </div>
                <div>
                  <div
                    onClick={() =>
                      openInNewTab(
                        "http://partners.koreainvestment.com/?lang=en&ckattempt=1"
                      )
                    }
                    class="koreaGroupLink"
                  >
                    Korea Investment Partners
                  </div>
                  <div
                    onClick={() =>
                      openInNewTab("https://sb.koreainvestment.com/")
                    }
                    class="koreaGroupLink"
                  >
                    Korea Investment Saving Bank
                  </div>
                  <div
                    onClick={() => openInNewTab("http://kicf.co.kr/")}
                    class="koreaGroupLink"
                  >
                    Korea Investment Capital
                  </div>
                  <div
                    onClick={() => openInNewTab("https://www.kakaobank.com/")}
                    class="koreaGroupLink"
                  >
                    Kakao Bank of Korea
                  </div>
                </div>
              </div>
              <div class="groupSelect">
                <select
                  onChange={(e) => console.log(openInNewTab(e.target.value))}
                  class="form-select selectFooter monsterratMedium f09r"
                  aria-label="Default select example"
                >
                  {groupList.map((group, i) => {
                    return (
                      <option
                        key={i}
                        class="subPathOption f09r"
                        value={group.site}
                      >
                        {group.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            {/* <div class="footerMenuWrapper">
          <div class="footerMenu">
            <div class="menu nav-link monsterratMedium">About Us</div>
            <div class="menu nav-link monsterratMedium">Contact</div>
            <div class="menu nav-link monsterratMedium">Career</div>
            <div class="menu nav-link monsterratMedium">FAQ</div>
          </div>
          <div class="footerMenu ">
            {" "}
            <div class="menu nav-link monsterratMedium">Disclaimer</div>
            <div class="menu nav-link monsterratMedium">Terms</div>
            <div class="menu nav-link monsterratMedium">Privacy</div>
          </div>
        </div> */}
            {/* <div class="socmedWrapper">
          <div class="socmedContainer">
            <div class="logoKisiWrapper">
              <img
                // onClick={() => {
                //   changePage("/");
                // }}
                src={kisiLogo}
                alt="logo-kisi"
                class="logoKisiFooter"
              />
            </div>
          </div>
        </div> */}
          </div>
          <div class="lowerFooterContainer">
            {" "}
            <div class="lowerFooter">
              {page.substring(0, 12) == "/tentangkami" ||
              page == "/" ||
              (page.substring(0, 7) == "/produk" &&
                page.substring(8, page.length) !== "reksadana" &&
                page.substring(8, page.length) !== "saham") ||
              page == "/kisimobile" ||
              page == "/koins" ? (
                <div class="addressFooter socmedFooterContainer socmedFooterContainer2">
                  <div class="socmedFooterWrapper">
                    {" "}
                    <div class="temukanTextFooter text-left monsterratBold grey f15r">
                      Mulai Sekarang!
                      <div class="followSocmedImgContainer socmedFooter">
                        <div class="flex storeFooterDiv">
                          <div class="text-center ">
                            <img
                              onClick={() =>
                                openInNewTab(
                                  "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                                )
                              }
                              class="downloadImgFooter mb-2"
                              src={playStore}
                            />
                          </div>
                          <div class="text-center mx-2">
                            <img
                              onClick={() =>
                                openInNewTab(
                                  "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                                )
                              }
                              class="downloadImgFooter mb-2"
                              src={appStore}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          textAlign: "left",
                        }}
                        class="f07r monsterratMedium black mt-2"
                      >
                        PT Korea Investment And Sekuritas Indonesia berizin dan <br></br> diawasi oleh Otoritas Jasa Keuangan
                      </div>
                    </div>{" "}
                  </div>
                </div>
              ) : (
                <div class="addressFooter socmedFooterContainer socmedFooterContainer2">
                  <div class="socmedFooterWrapper">
                    {" "}
                    <div class="temukanTextFooter monsterratBold grey f15r">
                      Temukan Kami
                      <div class="followSocmedImgContainer socmedFooter">
                        <img
                          class="socmedFooterImg"
                          src={instagramLogo}
                          onClick={() => {
                            openInNewTab(
                              "https://www.instagram.com/kisi_sekuritas/"
                            );
                          }}
                        />
                        <img
                          class="socmedFooterImg"
                          src={tiktokLogo}
                          onClick={() => {
                            openInNewTab(
                              "https://www.tiktok.com/@kisi_sekuritas"
                            );
                          }}
                        />
                        <img
                          class="socmedFooterImg "
                          src={fbLogo}
                          onClick={() => {
                            openInNewTab(
                              "https://www.facebook.com/koreainvestment.id/"
                            );
                          }}
                        />
                        <img
                          class="socmedFooterImg "
                          src={linkedinLogo}
                          onClick={() => {
                            openInNewTab(
                              "https://www.linkedin.com/company/korea-investment-and-sekuritas-indonesia-pt/mycompany/"
                            );
                          }}
                        />

                        <img
                          class="socmedFooterImg twitFil"
                          src={twitterLogo}
                          onClick={() => {
                            openInNewTab("https://x.com/kisi_sekuritas");
                          }}
                        />
                      </div>
                    </div>
                    <div
                        style={{
                          textAlign: "left",
                        }}
                        class="f07r monsterratMedium black mt-2"
                      >
                        PT Korea Investment And Sekuritas Indonesia berizin dan <br></br> diawasi oleh Otoritas Jasa Keuangan
                      </div>
                  </div>
                </div>
              )}
              <div class="lembagaWrapper">
                {/* <div>
              <div class="lembagaContainer bottom-vertical ">
                <img
                  class="lembagaImg ojk"
                  src={ojk}
                  onClick={() => openInNewTab("https://www.ojk.go.id/")}
                />
              </div>
              <div class="footerSmallText monsterratMedium">
                Izin Perantara Pedagang Efek
              </div>{" "}
            </div> */}
                <div>
                  <div class="lembagaContainer bottom-vertical ">
                    <img
                      class="lembagaImg idx"
                      src={idx}
                      onClick={() => openInNewTab("https://www.idx.co.id/")}
                    />
                  </div>
                  <div class="footerSmallText monsterratMedium">
                    Anggota Bursa Efek Indonesia
                  </div>
                </div>
                <div>
                  <div class="lembagaContainer bottom-vertical">
                    <img
                      class="lembagaImg idc"
                      src={idclear}
                      onClick={() => openInNewTab("https://www.idclear.co.id/")}
                    />
                  </div>
                  <div class="footerSmallText monsterratMedium">
                    Terdaftar di Kliring Penjaminan Efek Indonesia
                  </div>{" "}
                </div>
                <div>
                  <div class="lembagaContainer bottom-vertical">
                    <img
                      class="lembagaImg ksei"
                      src={ksei}
                      onClick={() => openInNewTab("https://www.ksei.co.id/")}
                    />
                  </div>
                  <div class="footerSmallText monsterratMedium">
                    Terdaftar di Kustodian Sentra Efek Indonesia
                  </div>
                </div>
                <div>
                  <div class="lembagaContainer bottom-vertical">
                    <img
                      class="lembagaImg sipf"
                      src={sipf}
                      onClick={() =>
                        openInNewTab("https://www.indonesiasipf.co.id/")
                      }
                    />
                  </div>
                  <div class="footerSmallText monsterratMedium">
                    Anggota Dana Perlindungan Modal
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bottomFooter">
            <div id="copyrightContainer">
              <div class="copyrightBottom monsterratRegular">
                <div class="logoKisiWrapper">
                  <img
                    onClick={() => {
                      changePage("/");
                    }}
                    src={kisiLogo}
                    alt="logo-kisi"
                    class="logoKisiFooter"
                  />
                </div>
                <div class="copyrightFooter nowrap">
                  Copyright © 2023 PT Korea Investment And Sekuritas Indonesia
                </div>
              </div>
            </div>
            <div class="lowerFooterLinkContainer center-vertical">
              <div class="lowerFooterLinkWrapper flex">
                {" "}
                <div
                  onClick={() => {
                    navigate("/tentangkami/profile");
                  }}
                  class="lowerFooterLink monsterratRegular white  noBorder"
                >
                  Tentang Kami
                </div>
                <div
                  onClick={() => {
                    navigate("/kebijakan-privasi", {
                      state: { page: "kebijakan_privasi" },
                    });
                  }}
                  class="lowerFooterLink monsterratRegular white"
                >
                  Kebijakan Privasi
                </div>
                <div
                  onClick={() => {
                    navigate("/disclaimer", { state: { page: "disclaimer" } });
                  }}
                  class="lowerFooterLink monsterratRegular white"
                >
                  Disclaimer
                </div>
                {/* <div
              onClick={() => {
                navigate("/disclaimer", { state: { page: "informasi" } });
              }}
              class="lowerFooterLink monsterratRegular white nowrap wAuto768"
            >
              Informasi Perdagangan
            </div> */}
                <div
                  onClick={() => {
                    navigate("/tentangkami/karir", {
                      state: { page: "informasi" },
                    });
                  }}
                  class="lowerFooterLink monsterratRegular white"
                >
                  Karir
                </div>
              </div>
            </div>

            <div class="copyright monsterratLight white copyrightMobile">
              Copyright © 2023 PT Korea Investment And Sekuritas Indonesia
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
