import "./style.css";
import React, { useEffect, useState } from "react";
import margin from "../../../assets/margin.jpg";
import PageLocation from "../../../components/PageLocation";
import marginBanner from "../../../assets/marginBanner.png";
import playStore from "../../../assets/playStore.png";
import appStore from "../../../assets/appStore.png";
import regAccInterest from "../../../assets/regAccInterest.png";
import marginAccInterest from "../../../assets/marginAccInterest.png";
import FollowSocmed from "../../../components/FollowSocmed";
import koins from "../../../assets/koins.png";
import kisiMobile from "../../../assets/kisiMobile.png";
import letter from "../../../assets/letter.png";
import mail from "../../../assets/email.png";
import Aos from "aos";
import marginCheck from "../../../assets/marginCheck.png";
import marginTimeline from "../../../assets/marginTimeline.jfif";
import { server, storageLocation } from "../../../config/server";
import manualPengisianKyc from "../../../assets/manualPengisianKyc.pdf";
import manualFpreRegulerMargin from "../../../assets/manualFpreRegulerMargin.pdf";
import manualRdnCIMB from "../../../assets/manualRdnCIMB.pdf";
import manualRdnBCA from "../../../assets/manualRdnBCA.pdf";
import manualRdnMandiri from "../../../assets/manualRdnMandiri.pdf";
import { useNavigate } from "react-router";
export function Margin() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <div className={`${imageLoaded ? "o1" : "o0"}`}>
      {/* <PageLocation left="Home"  right="Margin" subPath="Margin" /> */}
      {/* <div class="imageDivWrapper \" 
              >
        <img 
        onLoad={()=>{setImageLoaded(true)}}
          src={margin}
          data-aos="fade-up"
          data-aos-duration="3000"
          alt="banner image"
          class={`imageZ1`}
          style={{marginLeft:'1.5rem'}}
        />
        <div class="insideWrapperAbsolute marginImgDiv">
        <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
      </div> */}
      <div class="imageDivWrapper   ">
        {" "}
        <img
          class="w100 pointer"
          onLoad={() => {
            setImageLoaded(true);
          }}
          src={marginBanner}
          onClick={(e) => navigate("/kisimobile")}
        ></img>
        {/* <img
          onLoad={() => {
            setImageLoaded(true);
          }}
          data-aos="fade-up"
          data-aos-duration="2000"
          src={margin}
          alt="banner image"
          class={`imageZ1 marginImg`}
        />
        <div class="insideWrapperAbsolute centerAbsolute768 sahamImgDiv marginDownloadDiv">
          <div class="downloadButtonGroup">
            <div class=" etfDownloadButtonContainer">
              <button
                class=" monsterratBlack blue-btn rounded-pill koinsDownload downloadButton  f1r px-5"
                onClick={() => navigate("/kisimobile")}
              >
                DOWNLOAD SEKARANG
              </button>

              <div class="flex mobileMarketplaceContainer">
                <div class="  flexStart playStore">
                  <img
                    class="mobileMarketplace"
                    src={playStore}
                    onClick={() =>
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=id.co.kisi.kisimobile&hl=in&gl=US"
                      )
                    }
                  />
                </div>
                <div class="  flexEnd appStore">
                  <img
                    class="mobileMarketplace"
                    src={appStore}
                    onClick={() =>
                      openInNewTab(
                        "https://apps.apple.com/id/app/kisi-mobile/id1493151464"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="insideWrapperAbsolute sahamBannerText marginBannerText">
          <div class="sahamBannerTitle monsterratBlack marginBannerTitle">
            <span style={{ color: "#fbb03c" }}>
              PROMO MARGIN <br></br> ACCOUNT
            </span>
            <br></br>
            <div>
              <span class="f9r">12%</span>
              <span class="f15r">/tahun</span>
            </div>
            <div class="f1r marginPersenImg">
              <span>
                <img class="marginCheck" src={marginCheck}></img> Fee Beli 0.13%
                dan Jual 0.23%
              </span>{" "}
              <span>
                <img class="marginCheck" src={marginCheck}></img> 100+ pilihan
                saham
              </span>
              <div>
                {" "}
                <span>
                  <img class="marginCheck" src={marginCheck}></img> Periode
                  promosi Januari s/d Desember 2023
                </span>
              </div>
            </div>

            <p class="sahamBannerSubtitle"></p>
          </div>
        </div> */}
      </div>

      <div class="textCenter jenisAkunContainer sahamContainer pb-5">
        {/* <div class="monsterratBold grey f2r"></div> */}
        <div class="monsterratExtraBold mt-3 mx-auto  f275r  blue-text-1 mw90768">
          AKUN MARGIN
        </div>

        <div class="monsterratMedium grey  px-4 f12r">
          Akun Margin merupakan jenis akun untuk Nasabah yang ingin menggunakan
          fasilitas pembiayaan transaksi atau pinjaman yang memungkinkan Nasabah
          memperoleh nilai transaksi pembelian dengan jumlah lebih besar dan
          bunga yang lebih kecil dari Akun Reguler.
        </div>
      </div>
      <div class="sahamTextContainer px-2">
        <ul
          style={{ textAlign: "left" }}
          class=" f12r grey  monsterratMedium lh2"
        >
          <li>
            Minimum deposit dana dan/atau <i>Liquidity Value</i> sebesar
            Rp 50.000.000,-.
          </li>
          <li>
            <i>Fee</i> transaksi beli 0,13% dan jual 0,23%.
          </li>
          <li>
            Penggunaan <i>Limit Margin</i> ((<i>Cash</i> T+2 +{" "}
            <i>Liquidity Value</i> - <i>Order Buy</i> ) x <i>Stock Multiple*</i> )<br></br>
            Keterangan: <br></br>
            <i>Cash</i> T+2: dana yang tersedia pada T+2 portofolio. <br></br>
            <i>Liquidity Value</i> : nilai <i>liquidity value</i> pada
            portofolio. <br></br>
            <i>Stock Multiple</i> : ketentuan angka perkalian.
          </li>
          <li>Bunga penggunaan Margin 18% per tahun.</li>
          <li>
            Batas maksimal penggunaan <i>Margin Ratio</i> 65% dan akan dilakukan{" "}
            <i>Margin Call</i> hari ke-1 sampai dengan hari ke-3 jika melewati
            batas maksimal penggunaan <i>Margin Ratio</i> maka pada hari ke-4
            dikenakan <i>Forced Sell</i>.
          </li>
          <li>
            Jika <i>Margin Ratio</i> mencapai 80% maka akan dikenakan{" "}
            <i>Forced Sell</i> tanpa pemberitahuan.
          </li>
          <li>
            <b
              class="blue-text-1 pointer"
              onClick={(e) => {
             
                  navigate('/capping/margin')
           
              }}
            >
              Klik untuk melihat
            </b>{" "}
            <i>List Capping</i> saham Margin.
          </li>
        </ul>
        <div class="monsterratExtraBold my-5 mx-auto  f2r text-center blue-text-1 mw90768">
          Tabel <i>Stock Multiple</i>
        </div>
        <table class="table f12r table-bordered textCenter monsterratMedium lh25">
          <thead>
            <tr>
              <th scope="col">Capping</th>
              <th scope="col">
                {" "}
                <i>Multiple</i> (Penggunaan Fasilitas Margin)
              </th>
              <th scope="col">Jumlah Saham Marjin (Per Januari 2025)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>75%</th>
              <td>4.0 x</td>
              <td>41</td>
            </tr>
            <tr>
              <th>70%</th>
              <td>3.3 x</td>
              <td>3</td>
            </tr>
            <tr>
              <th>60%</th>
              <td>2.5 x</td>
              <td>19</td>
            </tr>
            <tr>
              <th>50%</th>
              <td>2.0 x</td>
              <td>25</td>
            </tr>
            <tr>
              <th>35%</th>
              <td>1.5 x</td>
              <td>51</td>
            </tr>
            <tr>
              <th>10%</th>
              <td>1.0 x</td>
              <td>34</td>
            </tr>
            <tr>
              <th>TOTAL</th>
              <td>-</td>
              <td>173</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* <FollowSocmed /> */}
    </div>
  );
}
